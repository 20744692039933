import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  createCapitalCounselServices,
  createPurchaseAccompanyingServices,
} from '@/api/AdditionalServices/createAdditionalServices';
import apiManager from '@/api/AxiosInstance';
import { deleteInterestProducts, postInterestProducts, postProductInquiry, postProductInquiryCall } from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import PhoneCallFloationgButton from '@/components/Common/Button/PhoneCallFloationgButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ButtonPopup, { ButtonPopupProps } from '@/components/Common/Popup/ButtonPopup';
import ProductInquiryQr from '@/components/Common/Popup/Contents/ProductInquiryQr';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import BubbleComponent from '@/components/Common/Tooltip/BubbleComponent';
import { ChatOutlineIcon, PhoneRingOutlineIcon } from '@/components/Icon';
import CapitalGuidePopup from '@/components/More/CaptialCounselService/CapitalGuidePopup';
import AccompanyingGuidePopup from '@/components/More/PurchaseAccompanyingService/AccompanyingGuidePopup';
import {
  IS_ALREADY_APPLY_CAPITAL_COUNSEL_SERVICE,
  IS_ALREADY_APPLY_PURCHASE_ACCOMPANYING_SERVICE,
} from '@/const/additionalService';
import { colors } from '@/const/colors';
import { ACTUAL_REPRESENTATIVE_PHONE_NUMBER, PRODUCT_DETAIL } from '@/const/common';
import { ALREADY_PRODUCT_INQUIRY_REQUESTED } from '@/const/errorCode';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import { memberAtom, updateAdditionalServiceAtom } from '@/store/member';
import { phoneCall } from '@/utils/common';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Checkbox from '@mui/material/Checkbox';

type ProductDetailProps = {
  product: ProductDetailResponse;
  isShowBubble: boolean;
};

interface PurchaseInquiryInfo {
  productId: number;
  name: string;
  phoneNumber: string;
  loaded: string;
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ProductDetailFooter = ({ product, isShowBubble }: ProductDetailProps) => {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const authRedirect = useAuthRedirect();
  const { showToast } = useToastContext();

  const [memberAtomData] = useAtom(memberAtom);
  const [, updateAdditionalService] = useAtom(updateAdditionalServiceAtom);

  const [isNearBottom, setIsNearBottom] = useState(false);
  const threshold = 20;

  const [isAlreadyApplyCapitalService, setIsAlreadyApplyCaptialService] = useState(false);
  const [isAlreadyApplyAccompanyingService, setIsAlreadyApplyAccompanyingService] = useState(false);

  const [interestProductId, setInterestProductId] = useState<number | null>();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [isOpenAccompanyingServiceModal, setIsOpenAccompanyingServiceModal] = useState(false);
  const [isOpenCapitalModal, setIsOpenCapitalModal] = useState(false);

  const inquiryForm = useRef<PurchaseInquiryInfo>();
  let inquiryFormData = inquiryForm.current;

  const [purchaseInquiryInfo, setPurchaseInquiryInfo] = useState<PurchaseInquiryInfo>({
    productId: product.id,
    name: '',
    phoneNumber: '',
    loaded: '',
  });
  const [buttonPopupInfo, setButtonPopupInfo] = useState<ButtonPopupProps>({
    isShow: false,
    onClose: () => {},
    buttons: [],
  });

  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    textRightBtn: '',
    onClickRightBtn: () => {},
    textLeftBtn: '',
    onClickLeftBtn: () => {},
  });

  const buttonRef = useRef<HTMLDivElement>(null);
  const [bubblePosition, setBubblePosition] = useState(0);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.clientWidth;
      setBubblePosition(buttonWidth / 4);
    }
  }, []);

  useEffect(() => {
    if (product) {
      setInterestProductId(product?.interestedProductId || null);
    }
  }, [product]);

  useEffect(() => {
    if (memberAtomData) {
      setPurchaseInquiryInfo({
        productId: product.id,
        name: memberAtomData.name,
        phoneNumber: memberAtomData.phoneNumber,
        loaded: product.loaded?.code || '',
      });

      setIsAlreadyApplyCaptialService(memberAtomData.isAlreadyApplyCapitalCounselService);
      setIsAlreadyApplyAccompanyingService(memberAtomData.isAlreadyApplyPurchaseAccompanyingService);
    }
  }, [memberAtomData]);

  const startPhoneCall = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    postProductInquiryCallMutation.mutate(product.id);
    phoneCall(product.sellerSafetyNumber);
  };

  const startChatting = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    apiManager
      .post('/api/v1/chat-rooms', { productId: product.id })
      .then((response) => {
        if (response) {
          const responseData: ChattingDetailResponse = response.data;
          if (responseData.id) {
            navigate(`/chatting/room/${responseData.id}`);
          } else {
            navigate(`/chatting/room`, { state: { chatRoomData: responseData } });
          }
        }
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const buttons = [
    {
      icon: <PhoneRingOutlineIcon color={colors.gray[6]}></PhoneRingOutlineIcon>,
      label: '전화문의',
      description: ' * 판매자의 개인정보 보호를 위해 050 안심번호로 연결됩니다.',
      onClick: startPhoneCall,
    },
    {
      icon: <ChatOutlineIcon color={colors.gray[6]}></ChatOutlineIcon>,
      label: '채팅문의',
      onClick: startChatting,
    },
  ];

  const postInterestProductMutation = useMutation((productId: number) => postInterestProducts(productId), {
    onMutate: async () => {
      await queryClient.cancelQueries('productDetail');
      setIsMutating(true);
      setInterestProductId(0);
    },
    onSuccess: (response) => {
      if (response) {
        setInterestProductId(response.data);
      }
    },
    onError: () => {
      setInterestProductId(null);
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    onSettled: () => {
      setIsMutating(false);
    },
  });

  const createInterestProduct = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id)) || isMutating) {
      return;
    }
    postInterestProductMutation.mutate(product.id);
  };

  const deleteInterestProductMutation = useMutation(
    (interestProductId: number) => deleteInterestProducts(interestProductId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries('productDetail');
        setIsMutating(true);

        const prevData = interestProductId;

        setInterestProductId(null);
        return { prevData };
      },
      onError: (error, response, context) => {
        setInterestProductId(context?.prevData);
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      onSettled: () => {
        setIsMutating(false);
      },
    },
  );

  const deleteInterestProduct = () => {
    if (isMutating) {
      return;
    }
    if (interestProductId) {
      deleteInterestProductMutation.mutate(interestProductId);
    }
  };

  const clickLikeButton = () => {
    if (!!interestProductId) {
      deleteInterestProduct();
    } else {
      createInterestProduct();
    }
  };

  const onCloseButtonPopup = () => {
    setButtonPopupInfo({ isShow: false, onClose: () => {}, buttons: [] });
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    if (value) {
      setPurchaseInquiryInfo({ ...purchaseInquiryInfo, [name]: value });
    }
  }

  const isNotDirectProduct = product.salesType?.code === 'ASSURANCE' || product.salesType?.code === 'CONSIGNMENT';

  const onClickInquiry = () => {
    if (isMobile) {
      if (!authRedirect(PRODUCT_DETAIL(product.id))) {
        return;
      }

      setButtonPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: product.truckName,
        buttons: buttons,
      });
    } else {
      setButtonPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: '차량 구매문의',
        buttons: [],
        children: <ProductInquiryQr isNotDirectProduct={false} productId={product.id} />,
        minWidth: 520,
      });
    }
  };

  const updateInquiryInfo = (data: PurchaseInquiryInfo) => {
    inquiryFormData = data;
    setPurchaseInquiryInfo({ ...data });
  };

  const onClickPhoneCall = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    if (isMobile) {
      const phoneNumberToCall =
        product.salesPeople?.safetyNumber || product.salesPeople?.phoneNumber || ACTUAL_REPRESENTATIVE_PHONE_NUMBER;
      postProductInquiryCallMutation.mutate(product.id);
      phoneCall(phoneNumberToCall);
    } else {
      setButtonPopupInfo({
        isShow: true,
        onClose: onCloseButtonPopup,
        title: '차량 구매문의',
        minWidth: 520,
        buttons: [],
        children: (
          <ProductInquiryQr
            isNotDirectProduct
            productId={product.id}
            purchaseInquiryInfo={purchaseInquiryInfo}
            updateInquiryInfo={updateInquiryInfo}
            handleClickButton={onClickProductPurchaseInquiry}
          />
        ),
      });
    }
  };

  const postProductPurchasingInquiryMutation = useMutation(
    (request: ProductPurchasingInquiryRequest) => postProductInquiry(request),
    {
      onSuccess: (response) => {
        if (response) {
          onCloseButtonPopup();
          setIsShowPopup(true);
          setPopupInfo({
            isShow: true,
            title: '구매문의 등록 완료',
            textContent: '담당자가 확인 후</br>연락 드릴 예정입니다.',
            textRightBtn: '확인',
            onClickRightBtn: () => {
              setIsShowPopup(false);
            },
          });
        }
      },
      onError: (error: AxiosError) => {
        if (error.code === ALREADY_PRODUCT_INQUIRY_REQUESTED) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const onClickProductPurchaseInquiry = () => {
    const request: ProductPurchasingInquiryRequest = {
      productId: inquiryFormData?.productId,
      name: inquiryFormData?.name || '',
      requestPhoneNumber: inquiryFormData?.phoneNumber || '',
      loaded: inquiryFormData?.loaded || '',
    };
    postProductPurchasingInquiryMutation.mutate(request);
  };

  const postProductInquiryCallMutation = useMutation((productId: number) => postProductInquiryCall(productId), {
    onSuccess: () => {},
    onError: () => {},
  });

  const handleClickPurchaseAccompanyingService = () => {
    setIsOpenAccompanyingServiceModal(true);
  };

  const postCapitalCounselServices = useMutation(
    (request: AdditionalServicesApplyRequest) => createCapitalCounselServices(request),
    {
      onSuccess: () => {
        showToast('화물차 대출 상담 서비스를 신청했어요.', 'success', 'bottom');
        setIsAlreadyApplyCaptialService(true);
        updateAdditionalService(IS_ALREADY_APPLY_CAPITAL_COUNSEL_SERVICE);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
      onSettled: () => {
        setIsOpenCapitalModal(false);
      },
    },
  );

  const handleClickApplyOk = () => {
    if (isOpenCapitalModal) {
      const requestData = {
        name: memberAtomData?.name,
        phoneNumber: memberAtomData?.phoneNumber,
        productId: product.id,
      };

      postCapitalCounselServices.mutate(requestData);
    } else if (isOpenAccompanyingServiceModal) {
      const requestData = {
        name: memberAtomData?.name,
        phoneNumber: memberAtomData?.phoneNumber,
        productId: product.id,
      };

      postPurchaseAccompanyingServices.mutate(requestData);
    }

    setIsShowPopup(false);
  };

  const postPurchaseAccompanyingServices = useMutation(
    (request: AdditionalServicesApplyRequest) => createPurchaseAccompanyingServices(request),
    {
      onSuccess: () => {
        showToast('차량 구매 동행 서비스를 신청했어요.', 'success', 'bottom');
        setIsAlreadyApplyAccompanyingService(true);
        updateAdditionalService(IS_ALREADY_APPLY_PURCHASE_ACCOMPANYING_SERVICE);
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
      onSettled: () => {
        setIsOpenAccompanyingServiceModal(false);
      },
    },
  );

  const handleClickApply = () => {
    if (!authRedirect(PRODUCT_DETAIL(product.id))) {
      return;
    }

    setIsShowPopup(true);
    setPopupInfo({
      isShow: true,
      title: `${product.truckName}`,
      textContent: `해당 차량으로 ${
        isOpenCapitalModal ? '화물차 대출 상담' : isOpenAccompanyingServiceModal ? '차량 구매 동행' : ''
      }<br/>서비스를 신청할까요?`,
      textRightBtn: '신청하기',
      onClickRightBtn: () => {
        handleClickApplyOk();
      },
      textLeftBtn: '취소',
      onClickLeftBtn: () => {
        setIsShowPopup(false);
      },
    });
  };

  const handleCloseModal = () => {
    setIsOpenAccompanyingServiceModal(false);
    setIsOpenCapitalModal(false);
    setIsNearBottom(false);
  };

  const handleScroll = () => {
    const popupElement = popupRef.current;
    const contentElement = contentRef.current;

    if (!popupElement) return;
    if (!contentElement) return;

    const scrollTop = popupElement.scrollTop;
    const viewportHeight = popupElement.clientHeight;
    const scrollHeight = contentElement.scrollHeight;

    setIsNearBottom(scrollTop + viewportHeight >= scrollHeight - threshold);
  };

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 0);
  }, [isOpenAccompanyingServiceModal, isOpenCapitalModal]);

  const handleScrollBottom = () => {
    const popupElement = popupRef.current;
    const contentElement = contentRef.current;

    if (!popupElement) return;
    if (!contentElement) return;

    const scrollTop = popupElement.scrollTop;
    const viewportHeight = popupElement.clientHeight;
    const scrollHeight = contentElement.scrollHeight;

    if (scrollTop + viewportHeight < scrollHeight) {
      popupElement.scrollBy({
        top: viewportHeight,
        behavior: 'smooth',
      });
    } else {
      setIsNearBottom(true);
    }
  };

  return (
    <>
      <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
        <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
        <div className="flex flex-col items-center justify-center mr-4">
          <Checkbox
            {...label}
            checked={!!interestProductId || false}
            icon={<FavoriteBorder />}
            sx={{
              color: '#919191',
              '&.Mui-checked': {
                color: '#FF4D4F',
              },
              padding: '0px',
              justifyContent: 'center',
            }}
            checkedIcon={<Favorite />}
            onClick={clickLikeButton}
          />
          <p className="text-center text-gray-6 text-xs md:text-sm sm:text-xs">찜하기</p>
        </div>
        <div className="flex flex-1 items-center my-2 text-base">
          <div className="flex w-full">
            {isNotDirectProduct ? (
              <div className="flex gap-2 w-full">
                <BasicButton
                  name="대출상담 신청하기"
                  bgColor={colors.gray[0]}
                  borderColor={colors.gray[3]}
                  textColor={colors.gray[6]}
                  fontSize={16}
                  height={48}
                  fontWeight="bold"
                  onClick={() => setIsOpenCapitalModal(true)}
                />
                <BasicButton
                  name="전화 문의하기"
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={48}
                  fontWeight="bold"
                  onClick={onClickPhoneCall}
                />
              </div>
            ) : (
              <div className="relative flex gap-2 w-full h-[48px]" ref={buttonRef}>
                <BasicButton
                  name="구매동행 서비스"
                  bgColor={colors.gray[0]}
                  borderColor={colors.gray[3]}
                  textColor={colors.gray[6]}
                  fontSize={16}
                  height={48}
                  fontWeight="bold"
                  onClick={() => handleClickPurchaseAccompanyingService()}
                />

                <div
                  className={`${isShowBubble ? '' : 'hidden'} absolute top-[-50px] transform -translate-x-1/2 w-max`}
                  style={{
                    left: `calc(50% - ${bubblePosition}px)`,
                  }}
                  onClick={() => handleClickPurchaseAccompanyingService()}
                >
                  <BubbleComponent
                    text="<strong class='text-gray-0 text-[14px]'>안전한 거래를 위해!</strong>"
                    bgColor={colors.gray[7]}
                    padding="6px 12px"
                    borderRadius="1.5em"
                  />
                </div>
                <BasicButton
                  name="차주에게 연락하기"
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={48}
                  fontWeight="bold"
                  onClick={() => onClickInquiry()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ButtonPopup
        isShow={buttonPopupInfo.isShow}
        onClose={buttonPopupInfo.onClose}
        title={buttonPopupInfo.title}
        buttons={buttonPopupInfo.buttons}
        children={buttonPopupInfo.children}
        minWidth={216}
      ></ButtonPopup>
      <BasicPopup
        isShow={isShowPopup}
        title={popupInfo.title}
        textContent={popupInfo.textContent}
        textRightBtn={popupInfo.textRightBtn}
        onClickRightBtn={popupInfo.onClickRightBtn}
        textLeftBtn={popupInfo.textLeftBtn}
        onClickLeftBtn={popupInfo.onClickLeftBtn}
        minWidth={240}
      ></BasicPopup>

      <DownToUpDetailPopup
        ref={popupRef}
        isShow={isOpenAccompanyingServiceModal || isOpenCapitalModal}
        onClosePopup={handleCloseModal}
        title={
          isOpenCapitalModal ? '화물차 대출 상담 서비스' : isOpenAccompanyingServiceModal ? '차량 구매 동행 서비스' : ''
        }
        onScroll={handleScroll}
      >
        <div>
          {isOpenCapitalModal ? (
            <>
              <CapitalGuidePopup ref={contentRef} price={product?.price || 0} />
              <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
                <div className="absolute right-[16px] top-[-72px]">
                  <PhoneCallFloationgButton />
                </div>

                <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
                <BasicButton
                  name={
                    isAlreadyApplyCapitalService
                      ? '서비스 신청완료'
                      : isNearBottom
                      ? '서비스 신청하기'
                      : '아래로 내리기'
                  }
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={48}
                  fontWeight={'bold'}
                  isDisabled={isAlreadyApplyCapitalService}
                  onClick={isNearBottom ? handleClickApply : handleScrollBottom}
                />
              </div>
            </>
          ) : isOpenAccompanyingServiceModal ? (
            <>
              <AccompanyingGuidePopup ref={contentRef} />{' '}
              <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
                <div className="absolute right-[16px] top-[-72px]">
                  <PhoneCallFloationgButton />
                </div>

                <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
                <BasicButton
                  name={
                    isAlreadyApplyAccompanyingService
                      ? '서비스 신청완료'
                      : isNearBottom
                      ? '서비스 신청하기'
                      : '아래로 내리기'
                  }
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  height={48}
                  fontWeight={'bold'}
                  isDisabled={isAlreadyApplyAccompanyingService}
                  onClick={isNearBottom ? handleClickApply : handleScrollBottom}
                />
              </div>
            </>
          ) : null}
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default ProductDetailFooter;
