import React from 'react';

interface FilledBadgeProps {
  text: string;
  textColor: string;
  bgColor: string;
  rounded: string;
  fontWeight?: string;
  fontSize?: string;
  paddingX?: string;
}

const FilledBadge = ({ text, textColor, bgColor, rounded, fontWeight, fontSize, paddingX }: FilledBadgeProps) => {
  const getBgColor = () => {
    switch (bgColor) {
      case 'primary':
        return 'bg-primary';
      case 'red':
        return 'bg-red';
      case 'green':
        return 'bg-green-0';
      case 'blue-1':
        return 'bg-blue-1';
      default:
        return bgColor;
    }
  };

  return (
    <span
      className={`text-center w-auto px-2 py-1 break-keep ${rounded} ${fontWeight} ${textColor} ${getBgColor()} ${
        fontSize ? fontSize : 'text-sm'
      }

      ${paddingX ? paddingX : ''}
      `}
    >
      {text}
    </span>
  );
};

export default FilledBadge;
