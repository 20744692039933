import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { createLeaseServices } from '@/api/AdditionalServices/createAdditionalServices';
import { getLeaseTrucks } from '@/api/AdditionalServices/getAdditionalServices';
import BasicButton from '@/components/Common/Button/BasicButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import MenuHeader from '@/components/Header/MenuHeader';
import NewCarLeaseGuideView from '@/components/More/NewCarLeaseService/NewCarLeaseGuideView';
import { IS_ALREADY_APPLY_LEASE_SERVICE } from '@/const/additionalService';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useAuthRedirect from '@/hooks/useAuthRedirect';
import useNavigateBack from '@/hooks/useNavigateBack';
import { memberAtom, updateAdditionalServiceAtom } from '@/store/member';

const NewCarLeaseService = () => {
  const [memberAtomData] = useAtom(memberAtom);
  const [, updateAdditionalService] = useAtom(updateAdditionalServiceAtom);

  const [isAlreadyApply, setIsAlreadyApply] = useState(false);

  const [isNearBottom, setIsNearBottom] = useState(false);
  const threshold = 20;

  const { showToast } = useToastContext();

  const authRedirect = useAuthRedirect();

  const navigateBack = useNavigateBack();

  const PurchaseAccompanyingServiceRef = useRef<HTMLDivElement>(null);

  const [isPopupShow, setIsPopupShow] = useState(false);

  const { data: truckInfoList } = useQuery(['get-lease-trucks'], () => getLeaseTrucks(), {
    onSuccess: () => {},
    refetchOnMount: false,
  });

  useEffect(() => {
    if (memberAtomData) {
      setIsAlreadyApply(memberAtomData.isAlreadyApplyLeaseService);
    }
  }, [memberAtomData]);

  const handleScrollBottom = () => {
    const currentScroll = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (currentScroll + viewportHeight < documentHeight) {
      window.scrollBy({
        top: viewportHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    setIsNearBottom(scrollTop + viewportHeight >= documentHeight - threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, []);

  const handleClickApplyService = () => {
    if (!authRedirect('/new-car-lease')) {
      return;
    }
    setIsPopupShow(true);
  };

  const handleClickClosePopup = () => {
    setIsPopupShow(false);
  };

  const handleClickOkBtn = () => {
    postLeaseServices.mutate(truckInfoList[0].id);
    setIsPopupShow(false);
  };

  const postLeaseServices = useMutation((leaseTruckId: number) => createLeaseServices(leaseTruckId), {
    onSuccess: () => {
      showToast('견적 상담 신청이 완료되었어요.', 'success', 'bottom');
      setIsAlreadyApply(true);
      updateAdditionalService(IS_ALREADY_APPLY_LEASE_SERVICE);
    },
    onError: (error: AxiosError) => {
      if (error.code && error.message) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      }
    },
  });

  const handleClickPrev = () => {
    navigateBack();
  };

  return (
    <>
      <MenuHeader title="신차리스" onClickPrevBtn={handleClickPrev}></MenuHeader>

      <div className="w-full pt-[60px] pb-[56px]" ref={PurchaseAccompanyingServiceRef}>
        <NewCarLeaseGuideView data={truckInfoList?.[0]} />

        <div className="flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white">
          <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
          <BasicButton
            name={isAlreadyApply ? '견적 신청완료' : isNearBottom ? '견적 신청하기' : '아래로 내리기'}
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight={'bold'}
            isDisabled={isAlreadyApply}
            onClick={isNearBottom ? handleClickApplyService : handleScrollBottom}
          />
        </div>
      </div>

      <BasicPopup
        isShow={isPopupShow}
        title=""
        textContent='<div class="min-w-[288px] min-h-[114px] sm280:min-w-0 flex items-center justify-center text-[20px] leading-[35px]">견적 상담을<br/>신청하시겠어요?</div>'
        textLeftBtn="취소"
        onClickLeftBtn={() => handleClickClosePopup()}
        textRightBtn="신청하기"
        onClickRightBtn={() => handleClickOkBtn()}
      ></BasicPopup>
    </>
  );
};

export default NewCarLeaseService;
