import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import BasicPopup from '@/components/Common/Popup/BasicPopup';
import PhoneAuthAndTerms from '@/components/SignUp/PhoneAuthAndTerms';
import UserRegistrationForm from '@/components/SignUp/UserRegistrationForm';
import { signUpFormAtom } from '@/store/signUp';

const SignUp = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const errorCode = searchParams.get('code');

  const [isFirst, setIsFirst] = useState(true);
  const [signUpFormData] = useAtom(signUpFormAtom);
  const setSignUpFormData = useSetAtom(signUpFormAtom);

  const [isShowPopup, setIsShowPopup] = useState(false);

  const [memberInfo, setMemberInfo] = useState<MemberInfo>({
    marketing: false,
    terms: false,
    personalInfo: false,
    name: '',
    password: '',
    phoneNumber: '',
    authNumber: '',
    isSentCode: false,
    isAuthenticateCode: false,
  });

  const updateMemberInfo = (data: MemberInfo) => {
    setSignUpFormData(data);
  };

  const handleClickClosePopup = () => {
    setIsShowPopup(false);
  };

  useEffect(() => {
    setMemberInfo(signUpFormData);
  }, [signUpFormData]);

  useEffect(() => {
    if (errorCode === 'KAKAO_FAIL') {
      setIsShowPopup(true);
      searchParams.delete('code');
      setSearchParams(searchParams, { replace: true });
    }
  }, [errorCode]);

  return (
    <>
      {isFirst ? (
        <PhoneAuthAndTerms
          memberInfo={memberInfo}
          updateMemberInfo={(data) => updateMemberInfo(data)}
          isFirst={isFirst}
          setIsFirst={setIsFirst}
        ></PhoneAuthAndTerms>
      ) : (
        <UserRegistrationForm
          memberInfo={memberInfo}
          updateMemberInfo={(data) => updateMemberInfo(data)}
          isFirst={isFirst}
          setIsFirst={setIsFirst}
        ></UserRegistrationForm>
      )}

      <BasicPopup
        isShow={isShowPopup}
        title="카카오 로그인 실패"
        textContent={
          '<div class="text-[20px] sm360:mx-4 sm680:mx-4">카카오 계정에 등록된 <br/>휴대폰 번호가 없습니다.<br/>전화번호로 회원가입 해주세요.</div>'
        }
        textRightBtn="확인"
        onClickRightBtn={handleClickClosePopup}
      />
    </>
  );
};

export default SignUp;
