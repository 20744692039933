import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { BEFORE_SALE, COMPLETED, ORIGIN_DATA_REGISTER, PAUSE, SALE } from '../../const/productStatus';
import DownToUpDetailPopup from '../Common/Popup/DownToUpDetailPopup';
import InputPopup from '../Common/Popup/InputPopup';
import { EllipsisVerticalIcon } from '../Icon';
import apiManager from '@/api/AxiosInstance';
import { patchProductSalePrice } from '@/api/products/updateProducts';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { formatPrice } from '@/utils/common';

interface MenuDataType {
  code: string;
  desc: string;
  onClick?: () => void;
}

export interface MoreMenuData {
  id: number;
  status: string;
}

export interface MoreMenuProps {
  id: number;
  status?: string;
  isShow: boolean;
  onToggleMenu: () => void;
  setProductList?: React.Dispatch<React.SetStateAction<ProductDetailResponse[]>>;
  setLicenseList?: React.Dispatch<React.SetStateAction<License[]>>;
  isLicense?: boolean;
  confirmTitle?: string;
  size?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
  price?: number;
}

export default function ProductMoreMenu({
  id,
  status,
  isShow,
  onToggleMenu,
  setProductList,
  setLicenseList,
  isLicense = false,
  confirmTitle,
  size,
  price,
}: MoreMenuProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [actualSalePrice, setActualSalePrice] = useState<number>();
  const [menus, setMenus] = useState<MenuDataType[]>([]);
  const [isBasicPopUpShow, setIsBasicPopUpShow] = useState(false);
  const [path, setPath] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState(status || null);
  const { showToast } = useToastContext();
  const [isUsePriceInputPopup, setIsUsePriceInputPopup] = useState(false);
  const [popupProps, setPopupProps] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    componentContent: null,
    textLeftBtn: '',
    onClickLeftBtn: () => {},
    textRightBtn: '',
    onClickRightBtn: () => {},
  });

  useEffect(() => {
    if (isLicense) {
      setPath('license');
    } else {
      setPath('products');
    }
  }, []);

  const setMenuData = () => {
    switch (selectedStatus) {
      case ORIGIN_DATA_REGISTER:
      case BEFORE_SALE:
        setMenus([
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case SALE:
        setMenus([
          { code: 'COMPLETED', desc: '판매완료' },
          { code: 'PAUSE', desc: '판매중지' },
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case PAUSE:
        setMenus([
          { code: 'SALE', desc: '판매중' },
          { code: 'MODIFY', desc: '수정하기' },
          { code: 'DELETE', desc: '삭제하기' },
        ]);
        break;
      case COMPLETED:
        setMenus([{ code: 'DELETE', desc: '삭제하기' }]);
        break;
    }
    onToggleMenu();
  };

  const onClickMenu = () => {
    setMenuData();
  };

  const closeBasicPopUp = () => {
    setIsBasicPopUpShow(false);
  };

  const onClickDelete = () => {
    setTimeout(() => {
      setIsBasicPopUpShow(true);
    }, 100);

    setPopupProps({
      ...popupProps,
      isShow: true,
      title: confirmTitle,
      textContent: `${isLicense ? '번호판을 정말 삭제하시겠어요?' : '차량을 정말 삭제하시겠어요?'}`,
      componentContent: null,
      textLeftBtn: '취소',
      onClickLeftBtn: () => closeBasicPopUp(),
      textRightBtn: '삭제하기',
      onClickRightBtn: () => {
        onToggleMenu();
        deleteProduct();
      },
    });
  };

  const deleteProduct = () => {
    apiManager
      .delete(`/api/v1/${path}/${id}`)
      .then((response) => {
        if (!isLicense) {
          showToast('차량이 삭제되었어요.', 'success', 'bottom');
          if (setProductList) {
            setProductList((prev) => prev.filter((item) => item.id !== id));
          }
          const isVehicleDetailPage = /^\/products\/\d+$/.test(location.pathname);
          if (isVehicleDetailPage) {
            navigate('/products', { replace: true });
            queryClient.invalidateQueries({ queryKey: ['product-search'] });
          }
        } else if (setLicenseList) {
          setLicenseList((prev) => prev.filter((item) => item.id !== id));
          showToast('번호판이 삭제되었어요.', 'success', 'bottom');
        }
        closeBasicPopUp();
      })
      .catch((error) => {
        showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
      });
  };

  const handleToastMsg = (selectedStatus: string) => {
    switch (selectedStatus) {
      case SALE:
        return '판매중으로 변경되었어요.';
      case PAUSE:
        return '판매중지로 변경되었어요.';
      default:
        return '';
    }
  };

  const onClickChangeStatusToCompleted = (selectedStatus: string) => {
    setTimeout(() => {
      setIsBasicPopUpShow(true);
    }, 100);

    setPopupProps({
      ...popupProps,
      isShow: true,
      title: '',
      textContent:
        '<div class="text-[20px] sm280:text-[18px] leading-[30px]">판매 완료로 상태를</br>변경할까요?<br/><br/><div class="text-red text-[18px] sm280:text-[16px] leading-[26px] mx-8 sm280:mx-0">* 판매완료 처리 후 판매중으로<br/>상태 변경이 불가능합니다.</div></div>',
      componentContent: null,
      textLeftBtn: '취소',
      onClickLeftBtn: () => closeBasicPopUp(),
      textRightBtn: '확인',
      onClickRightBtn: () => {
        onToggleMenu();
        onClickChangeStatus(selectedStatus);
      },
    });
  };

  const showActualSalePricePopup = () => {
    setIsBasicPopUpShow(false);
    setIsUsePriceInputPopup(true);
  };

  const onClickChangeStatus = (selectedStatus: string) => {
    if (path) {
      apiManager
        .patch(`/api/v1/${path}/${id}/status`, { status: selectedStatus })
        .then((response) => {
          if (!isLicense) {
            const responseData: ProductDetailResponse = response.data;
            if (setProductList) {
              setProductList((prevData) =>
                prevData.map((item) => {
                  if (item.id === responseData.id) {
                    return { ...item, status: responseData.status };
                  }
                  return item;
                }),
              );
            }
            if (responseData && responseData.status.code == COMPLETED) {
              setIsBasicPopUpShow(false);
              setIsUsePriceInputPopup(true);
              showActualSalePricePopup();
            }
          } else if (setLicenseList) {
            const responseData: License = response.data;
            setLicenseList((prevData) =>
              prevData.map((item) => {
                if (item.id === responseData.id) {
                  return { ...item, status: responseData.status };
                }
                return item;
              }),
            );
          }
          setSelectedStatus(selectedStatus);
          const newToastMsg = handleToastMsg(selectedStatus);
          if (!!newToastMsg) {
            showToast(newToastMsg, 'success', 'bottom');
          }
        })
        .catch((error) => {
          showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
        });
    }
  };

  const onClickMenuItem = (code: string) => {
    onToggleMenu();
    switch (code) {
      case 'MODIFY':
        if (isLicense) {
          navigate(`/${path}/form/${id}`, { state: { isModify: true } });
        } else {
          navigate(`/${path}/sales/edit/${id}/#info`);
        }
        break;
      case 'DELETE':
        onClickDelete();
        break;
      case 'COMPLETED':
        if (isLicense) {
          onClickChangeStatus(code);
        } else {
          onClickChangeStatusToCompleted(code);
        }
        break;
      case 'PAUSE':
      case 'SALE':
        onClickChangeStatus(code);
        break;
    }
  };

  const patchActualPriceMutation = useMutation(
    (requset: { id: number; actualSalePrice: number }) => patchProductSalePrice(requset),
    {
      onSuccess: (response) => {
        const responseData: ProductDetailResponse = response.data;
        if (responseData) {
          showToast('실제 판매 가격 저장이 완료되었어요.', 'success', 'bottom');
          if (setProductList) {
            setProductList((prevData) =>
              prevData.map((item) => {
                if (item.id === responseData.id) {
                  return { ...item, actualSalePrice: responseData.actualSalePrice };
                }
                return item;
              }),
            );
          }
        }
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
    },
  );

  const modifyProductActualSalePrice = () => {
    if (price && actualSalePrice) {
      if (price + 1000 < actualSalePrice || price - 1000 > actualSalePrice) {
        setIsBasicPopUpShow(true);
        setPopupProps({
          ...popupProps,
          isShow: true,
          title: '',
          textContent:
            '<div class="text-[20px] sm280:text-[15px] leading-[30px] mx-8 sm280:mx-0">입력하신 실제 판매 금액과<br/>기존 금액의 차이가 큽니다.<br/>이대로 입력하시겠어요?</div>',
          componentContent: (
            <div className="text-gray-6 text-[16px] sm280:text-[14px] leading-[26px] mb-6">
              <div>기존 금액 {formatPrice(String(price))}</div>
              <div>실제 판매 금액 {formatPrice(String(actualSalePrice))}</div>
            </div>
          ),
          textLeftBtn: '다시 입력',
          onClickLeftBtn: () => {
            closeBasicPopUp();
            setIsUsePriceInputPopup(true);
          },
          textRightBtn: '확인',
          onClickRightBtn: () => {
            closeBasicPopUp();
            updateActualSalePrice();
          },
        });
      } else {
        updateActualSalePrice();
      }
    }
  };

  const updateActualSalePrice = () => {
    if (actualSalePrice) {
      const request = {
        id: id,
        actualSalePrice: actualSalePrice,
      };
      patchActualPriceMutation.mutate(request);
    }
  };

  return (
    <>
      <button onClick={onClickMenu}>
        <EllipsisVerticalIcon />
      </button>
      <BasicPopup
        isShow={isBasicPopUpShow}
        title={popupProps.title}
        textContent={popupProps.textContent}
        componentContent={popupProps.componentContent}
        textLeftBtn={popupProps.textLeftBtn}
        onClickLeftBtn={popupProps.onClickLeftBtn}
        textRightBtn={popupProps.textRightBtn}
        onClickRightBtn={popupProps.onClickRightBtn}
      ></BasicPopup>
      <InputPopup
        isShow={isUsePriceInputPopup}
        setIsShow={setIsUsePriceInputPopup}
        title="판매완료로 변경되었어요."
        content={`<span class="text-[18px] sm280:text-[14px]">실제 판매 금액을 입력해주세요.</span>`}
        placeholder="판매 금액"
        price={price}
        id={id}
        setProductList={setProductList}
        onClickOk={modifyProductActualSalePrice}
        inputValue={actualSalePrice}
        setInputValue={setActualSalePrice}
      />
      <DownToUpDetailPopup isShow={isShow} onClosePopup={onToggleMenu} title="메뉴">
        <div className="p-4">
          {menus?.map((item, index) => (
            <div
              key={index}
              className={`py-4 cursor-pointer ${item.code === 'DELETE' && 'text-red'}`}
              onClick={() => onClickMenuItem(item.code)}
            >
              {item.desc}
            </div>
          ))}
        </div>
      </DownToUpDetailPopup>
    </>
  );
}
