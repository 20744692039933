import React, { useState } from 'react';

import TextInputLabelOff from '../Input/TextInputLabelOff';
import { formatNumber, formatPrice } from '@/utils/common';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface ActualSalePriceInputPopupProps {
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  title: string;
  content: string;
  componentContent?: React.ReactNode;
  placeholder: string;
  price?: number;
  setProductList?: React.Dispatch<React.SetStateAction<ProductDetailResponse[]>>;
  onClickOk: () => void;
  inputValue: number | undefined;
  setInputValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  useErrorMessage?: boolean;
}

const InputPopup = ({
  isShow,
  setIsShow,
  id,
  title,
  content,
  componentContent,
  placeholder,
  price,
  onClickOk,
  inputValue,
  setInputValue,
  useErrorMessage = true,
}: ActualSalePriceInputPopupProps) => {
  const [actualSalePriceError, setActualSalePriceError] = useState(false);
  const [actualSalePriceErrorMessage, setActualSalePriceErrorMessage] = useState('');

  const onChangeActualSalePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    let price = String(value).replace(/[^0-9.]/g, '');
    if (/^[0-9]*$/.test(price) || price === '0') {
      if (Number(price) >= 100000) {
        price = String(99999);
      } else {
        setInputValue(Number(price));
      }
    }
    setInputValue(Number(price));
  };

  const onClickOkBtn = () => {
    if (inputValue || !useErrorMessage) {
      onClickOk();
      closePopup();
    } else {
      setActualSalePriceError(true);
      setActualSalePriceErrorMessage(`${placeholder}을 입력해주세요.`);
    }
  };

  const closePopup = () => {
    setIsShow(false);
    setActualSalePriceError(false);
    setActualSalePriceErrorMessage('');
    setInputValue(undefined);
  };

  return (
    <>
      <Dialog
        open={isShow}
        onClose={() => {
          setIsShow(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="min-w-[280px]"
      >
        <DialogTitle id="alert-dialog-title" className="text-center font-bold" sx={{ paddingTop: '32px' }}>
          <span className="break-words text-[20px] sm280:text-[15px] font-medium">{title}</span>
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: '240px',
            margin: 0,
            textAlign: 'center',
            padding: '18px',
            paddingBottom: '35px',
            paddingTop: '30px',
            fontSize: '18px',
          }}
        >
          <DialogContentText id="alert-dialog-description" sx={{ paddingBottom: '16px' }}>
            <span>
              <span className="break-all text-base" dangerouslySetInnerHTML={{ __html: content }}></span>
            </span>
          </DialogContentText>
          {componentContent && componentContent}
          <TextInputLabelOff
            name="actualPrice"
            placeholder={placeholder}
            value={(inputValue && formatNumber(String(inputValue), false)) || ''}
            height={36}
            fontSize={18}
            onChange={onChangeActualSalePrice}
            error={useErrorMessage && actualSalePriceError}
            errorMsg={actualSalePriceErrorMessage}
            inputMode="numeric"
            suffix="만원"
            autoFocus
          />
          {!actualSalePriceError && (
            <div className="text-left pt-[6px] text-gray-7 text-base sm280:text-[14px]">
              {price && <>기존 금액 [{formatPrice(String(price))}]</>}
            </div>
          )}
        </DialogContent>
        <div className="border-t w-full flex justify-bewteen items-center text-center">
          <div
            className="w-full border-l cursor-pointer"
            style={{ fontSize: '18px', fontWeight: '500', color: '#1E42A6', paddingTop: '12px', paddingBottom: '12px' }}
            onClick={onClickOkBtn}
          >
            확인
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InputPopup;
